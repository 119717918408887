<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.regional"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:region="state.params.regional"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:area="state.params.area"
                    v-model:region="state.params.regional"
                    v-model:value="state.params.kabupaten"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:area="state.params.area"
                    v-model:region="state.params.regional"
                    v-model:kabupaten="state.params.kabupaten"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterGudang
                    style="width:100%;"
                    v-model:area="state.params.area"
                    v-model:region="state.params.regional"
                    v-model:distributor="state.params.distributor"
                    v-model:value="state.params.gudang"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProduct
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.product"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterAdjustmentStockCategory
                    v-model:value="state.params.category"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    style="width:100%"
                    :options="state.statusLists"
                    v-model:value="state.params.status"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    v-model:value="state.params.adjustment_code"
                    placeholder="Cari kode adjustment..."
                    allow-clear
                    style="width:100%"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                row-key="id"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            title="List Produk"
                            placement="topRight">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnProductList(record)">
                                <i class="fa fa-list-alt" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasPermissions(['adjustment-stock.live-stock'])"
                            title="Detail Stock Live Di ATBusiness"
                            placement="topRight">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnLiveStockList(record)">
                                <i class="fa fa-list" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasPermissions(['adjustment-stock.history-approval'])"
                            title="List History Action"
                            placement="topRight">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnHistoryActionList(record)">
                                <i class="fa fa-users" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>
        <!-- end form tables -->

        <!-- product -->
        <ProductList
            v-if="visibleProductListModal"
            v-model:visible="visibleProductListModal"
            v-model:item="visibleProductListItemModal"/>

        <!-- live stock -->
        <LiveStockList
            v-if="visibleLiveStockListModal"
            v-model:visible="visibleLiveStockListModal"
            v-model:item="visibleLiveStockListItemModal"/>

        <!-- history action -->
        <HistoryActionList
            v-if="visibleHistoryActionListModal"
            v-model:visible="visibleHistoryActionListModal"
            v-model:item="visibleHistoryActionListItemModal"/>
        
        <!-- message errors -->
        <MessageErrors
            v-model:visible="state.errors.visible"
            :data="state.errors.data"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, h } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterGudang from '@/components/filter/FilterGudang'
import FilterProduct from '@/components/filter/FilterProduct'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterAdjustmentStockCategory from '@/components/filter/FilterAdjustmentStockCategory'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import MessageErrors from '@/components/Molecules/MessageErrors'
import ProductList from './ProductList'
import LiveStockList from './LiveStockList'
import HistoryActionList from './HistoryActionList'
import {
    hasRoles,
    hasPermissions,
    ROLE_ADMIN_BK,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'
import { includes } from 'lodash'

export default defineComponent({
    components: {
        DownloadExcel,
        MessageErrors,
        FilterDistributor,
        FilterGudang,
        FilterProduct,
        FilterRegional,
        FilterArea,
        FilterKabupatenKota,
        FilterAdjustmentStockCategory,
        ProductList,
        LiveStockList,
        HistoryActionList,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Adjustment',
                    dataIndex: 'adjustment_code',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'warehouse_code',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'warehouse',
                },
                {
                    title: 'Status Gudang',
                    dataIndex: 'warehouse_status',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Kategori',
                    dataIndex: 'category',
                },
                {
                    title: 'Catatan',
                    dataIndex: 'notes',
                    customRender: ({ text }) => h("div", { innerHTML: decodeHtml(text) }),
                },
                {
                    title: 'Tanggal Pengajuan',
                    dataIndex: 'date',
                },
                {
                    title: 'Action',
                    key: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/report/adjustment-stock-approvals',
                request: '/api/report/adjustment-stock-approvals',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                distributor: [],
                gudang: [],
                product: [],
                area: [],
                regional: [],
                kabupaten: [],
                category: [],
                status: 'waiting',
                adjustment_code: null,
                page: 1,
                "per-page": 10,
            }),
            errors: {
                visible: false,
                data: [],
            },
            statusLists: [
                {
                    label: "Waiting",
                    value: 'waiting',
                },
                {
                    label: "Approved",
                    value: 'approved',
                },
                {
                    label: "Rejected",
                    value: 'rejected',
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isRequest = item.status != 'waiting'
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const decodeHtml = (html) => {
            const textarea = document.createElement("textarea")
            textarea.innerHTML = html
            return textarea.value
        }

        // handle product list
        const visibleProductListModal = ref(false);
        const visibleProductListItemModal = ref(null);

        const btnProductList = (item = null) => {
            visibleProductListModal.value = true
            visibleProductListItemModal.value = item
        }

        // handle history action list
        const visibleHistoryActionListModal = ref(false);
        const visibleHistoryActionListItemModal = ref(null);

        const btnHistoryActionList = (item = null) => {
            visibleHistoryActionListModal.value = true
            visibleHistoryActionListItemModal.value = item
        }

        // handle live stock list
        const visibleLiveStockListModal = ref(false);
        const visibleLiveStockListItemModal = ref(null);

        const btnLiveStockList = (item = null) => {
            visibleLiveStockListModal.value = true
            visibleLiveStockListItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
            handleTableChange,
            btnProductList,
            visibleProductListModal,
            visibleProductListItemModal,
            btnHistoryActionList,
            visibleHistoryActionListModal,
            visibleHistoryActionListItemModal,
            btnLiveStockList,
            visibleLiveStockListModal,
            visibleLiveStockListItemModal,
            // only variable role
            hasRoles,
            hasPermissions,
            ROLE_ADMIN_BK,
        }
    },
});
</script>

<style scoped lang="scss">
.approve,
.reject {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;

    &.ant-radio-button-wrapper-checked {
        color: white !important;
    }
}
.approve {
    padding: 0px 8px !important;
    color: #009688;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #009688 !important;
        border-color: #009688 !important;
    }
}
.reject {
    padding: 0px 10px !important;
    color: #f44336;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #f44336 !important;
        border-color: #f44336 !important;
    }
}
</style>